import React from 'react';
import { Router } from '@reach/router';
import * as ROUTES from '../constants/routes';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@material-ui/styles';
import appTheme from '../app/ui/appTheme';
import loadable from '@loadable/component'
import Loading from '../components/Loading/index.js';

const Courses = loadable(
  () => import ('../app/pages/courses/courses.js'), {
    fallback: <Loading />,
  }
);
const Messages = loadable(
  () => import ('../app/pages/messages/messages.js'), {
    fallback: <Loading />,
  }
);
const Course = loadable(
  () => import ('../app/pages/courses/classGroups/courses/course.js'),
  {
    fallback: <Loading />,
  }
);
const Activity = loadable(
  () => import ('../app/pages/courses/classGroups/courses/topic/activity/activity.js'),
  {
    fallback: <Loading />,
  }
);
const Topic = loadable(
  () => import ('../app/pages/courses/classGroups/courses/topic'),
  {
    fallback: <Loading />,
  }
);
const SellCoursesPage = loadable(
  () => import ('../app/pages/admin/sell'),
  {
    fallback: <Loading />
  }
);
const ClassGroupCreation = loadable(
  () => import ('../app/pages/admin/classGroupCreation'),
  {
    fallback: <Loading />
  }
);

const AdminUsers = loadable(
  () => import ('../app/pages/admin/users'),
  {
    fallback: <Loading />
  }
);
const Admin = loadable(
  () => import ('../app/pages/admin'),
  {
    fallback: <Loading />,
  }
);
const Contact = loadable(
  () => import ('../app/pages/contact'),
  {
    fallback: <Loading />
  }
);
const ContactDeviceCheck = loadable(
  () => import ('../app/pages/contactDeviceCheck'),
  {
    fallback: <Loading />
  },
);
const EndOfCourse = loadable(
  () => import ('../app/pages/end-of-course'),
  {
    fallback: <Loading />
  }
);
const CourseDiagram = loadable(
  () => import ('../app/pages/admin/diagram'),
  {
    fallback: <Loading />
  }
);
const ContentPreview = loadable(
  () => import ('../app/pages/admin/contentPreview/ContentPreview'),
  {
    fallback: <Loading />
  }
);
const EmailTemplatePreview = loadable(
  () => import ('../app/pages/admin/preview/EmailTemplate'),
  {
    fallback: <Loading />
  }
);

const Tutor = loadable(
  () => import ('../app/pages/tutor/index'),
  {
    fallback: <Loading />
  }
);

const TutorProgressPage = loadable(
  () => import ('../app/pages/tutor/progress'),
  {
    fallback: <Loading />
  }
);

const TutorFeedbackPage = loadable(
  () => import ('../app/pages/tutor/feedback'),
  {
    fallback: <Loading />
  },
);

const TutorStatisticsPage = loadable(
  () => import ('../app/pages/tutor/statistics'),
  {
    fallback: <Loading />
  }
);

const TutorCompletionPage = loadable(
  () => import ('../app/pages/tutor/courseCompletion'),
  {
    fallback: <Loading />
  }
);

const CourseOverview = loadable(
  () => import ('../app/pages/courses/classGroups/courses/overview'),
  {
    fallback: <Loading />
  }
);

const CourseSchedule = loadable(
  () => import ('../app/pages/courses/classGroups/courses/schedule/schedule'),
  {
    fallback: <Loading />
  }
);

const Recordings = loadable(
  () => import ('../app/pages/admin/recordings'),
  {
    fallback: <Loading />
  }
);

const WebinarInfo = loadable(
  () => import ('../app/pages/admin/webinar-info'),
  {
    fallback: <Loading />
  }
);

const Account = loadable(
  () => import ('../app/pages/account/account'),
  {
    fallback: <Loading />
  }
);

const SignIn = loadable(
  () => import ('../app/pages/account/signIn'),
  {
    fallback: <Loading />
  }
);

const SignInWithLink = loadable(
  () => import ('../app/pages/account/signInWithLink'),
  {
    fallback: <Loading />
  }
);

const SignUp = loadable(
  () => import ('../app/pages/account/signUp'),
  {
    fallback: <Loading />
  }
);

const PwForget = loadable(
  () => import ('../app/pages/account/passwordForget'),
  {
    fallback: <Loading />
  }
);

const PwReset = loadable(
  () => import ('../app/pages/account/passwordReset'),
  {
    fallback: <Loading />
  }
);

const NotFound = loadable(
  () => import ('./404'),
  {
    fallback: <Loading />
  }
);

const TestRoom = loadable(
  () => import ('../app/pages/TestRoom'),
  {
    fallback: <Loading />
  }
);

const VideoTestRoom = loadable(
  () => import ('../app/pages/VideoTestRoom'),
  {
    fallback: <Loading />
  }
);



const SalesIntegration = loadable(
  () => import ('../app/pages/admin/tutorialScheduling'),
  {
    fallback: <Loading />
  }
);

const MessageAdminView = loadable(
  () => import ('../app/pages/admin/messageAdminView'),
  {
    fallback: <Loading />
  }
);

const DeviceCheck = loadable(
  () => import ('../app/pages/deviceCheck'),
  {
    fallback: <Loading />
  }
);

const BrowserCheck = loadable(
  () => import ('../app/pages/browserCheck'),
  {
    fallaback: <Loading />
  }
);

const CourseOverviewNoSignInPage = loadable(
  () => import ('../app/pages/schedule'),
  {
    fallback: <Loading />
  }
);

const translations = {
  title: 'Melio Education | Online Learning with Oxford & Cambridge Tutors',
};

const App = () => (
  <>
    <ThemeProvider theme={appTheme}>
      <Helmet>
        <title>{translations.title}</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>

      <Router>
        <NotFound default/>
        <TestRoom path={ROUTES.APP_TESTROOM}/>
        <VideoTestRoom path={ROUTES.APP_NEWTESTROOM}/>
        <DeviceCheck path={ROUTES.APP_DEVICE_CHECK}/>
        <BrowserCheck path={ROUTES.APP_BROWSER_CHECK}/>
        <Courses path={ROUTES.APP_COURSES}/>
        <Course path={ROUTES.APP_CLASSGROUP_COURSE}/>
        <Topic path={ROUTES.APP_CLASSGROUP_COURSE_TOPIC}
               isContentfulPreview={false}/>
        <Activity path={ROUTES.APP_CLASSGROUP_COURSE_ACTIVITY}/>
        <CourseOverview path={ROUTES.APP_CLASSGROUP_COURSE_OVERVIEW}/>
        <CourseSchedule path={ROUTES.APP_CLASSGROUP_COURSE_SCHEDULE}/>

        <EndOfCourse path={ROUTES.APP_END_COURSE}/>

        <CourseOverviewNoSignInPage
          path={ROUTES.APP_CLASS_GROUP_OVERVIEW}/>
        <CourseOverviewNoSignInPage
          path={ROUTES.APP_CLASS_GROUP_TUTORIAL_GROUP_OVERVIEW}/>

        <Admin path={ROUTES.APP_ADMIN}/>
        <CourseDiagram path={ROUTES.APP_ADMIN_COURSE_DIAGRAM}/>
        <SellCoursesPage path={ROUTES.APP_ADMIN_SELL}/>
        <ClassGroupCreation path={ROUTES.APP_ADMIN_CLASS_GROUP_CREATION}/>
        <ContentPreview path={ROUTES.APP_ADMIN_CONTENT_PREVIEW}>
          <EmailTemplatePreview path="emailTemplate/:entryId"
                                component={(d) => d}/>
        </ContentPreview>
        <Recordings path={ROUTES.APP_ADMIN_RECORDINGS}/>
        <AdminUsers path={ROUTES.APP_ADMIN_USERS}/>
        <WebinarInfo path={ROUTES.APP_ADMIN_WEBINAR_INFO}/>
        <SalesIntegration path={ROUTES.APP_ADMIN_TUTORIALS}/>
        <MessageAdminView path={ROUTES.APP_ADMIN_MESSAGE_VIEW}/>

        <Tutor path={ROUTES.APP_TUTOR}/>
        <Tutor path={ROUTES.APP_TUTOR_DASHBOARD}/>
        <TutorFeedbackPage path={ROUTES.APP_TUTOR_FEEDBACK}/>
        <TutorProgressPage path={ROUTES.APP_TUTOR_PROGRESS}/>
        <Activity path={ROUTES.APP_TUTOR_PROGRESS_ACTIVITY}/>
        <TutorStatisticsPage path={ROUTES.APP_TUTOR_STATISTICS}/>
        <TutorCompletionPage path={ROUTES.APP_TUTOR_COURSE_COMPLETION}/>
        <Activity path={ROUTES.APP_ADMIN_PREVIEW_ACTIVITY}
                  isContentfulPreview={true}/>

      <Contact path={ROUTES.APP_CONTACT}/>
      <ContactDeviceCheck path={ROUTES.APP_CONTACT_DEVICE_CHECK}/>

      <Account path={ROUTES.APP_ACCOUNT}/>
      <SignIn path={ROUTES.SIGN_IN}/>
      <SignInWithLink path={ROUTES.SIGN_IN_LINK}/>
      <SignUp path={ROUTES.SIGN_UP}/>
      <PwForget path={ROUTES.PASSWORD_FORGET}/>
      <PwReset path={ROUTES.PASSWORD_RESET}/>


      <Messages path={ROUTES.APP_MESSAGES}/>
    </Router>
    </ThemeProvider>
    </>
)

export default App;
