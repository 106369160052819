export const LANDING = '/';

export const APP_COURSES = '/app/courses';
export const APP_CLASSGROUP_COURSE = APP_COURSES + '/classGroups/:classGroupId/courses/:courseId';
export const APP_CLASSGROUP_COURSE_IDS = (classGroupId, courseId) => `/app/courses/classGroups/${classGroupId}/courses/${courseId}`;

export const APP_CLASSGROUP_COURSE_ACTIVITY = APP_CLASSGROUP_COURSE + '/activity/:activityId';
export const APP_CLASSGROUP_COURSE_ACTIVITY_IDS = (classGroupId, courseId, activityId) => APP_CLASSGROUP_COURSE_IDS(classGroupId, courseId) + `/activity/${activityId}`;

export const APP_CLASSGROUP_COURSE_TOPIC = APP_CLASSGROUP_COURSE + '/topic/:topicId';
export const APP_CLASSGROUP_COURSE_TOPIC_IDS = (classGroupId, courseId, topicId) => APP_CLASSGROUP_COURSE_IDS(classGroupId, courseId) + `/topic/${topicId}`;


export const APP_CLASSGROUP_COURSE_OVERVIEW = APP_CLASSGROUP_COURSE + '/overview';
export const APP_CLASSGROUP_COURSE_OVERVIEW_IDS = (classGroupId, courseId) => APP_CLASSGROUP_COURSE_IDS(classGroupId, courseId) + `/overview`;

export const APP_CLASSGROUP_COURSE_SCHEDULE = APP_CLASSGROUP_COURSE + '/schedule';
export const APP_CLASSGROUP_COURSE_SCHEDULE_IDS = (classGroupId, courseId) => APP_CLASSGROUP_COURSE_IDS(classGroupId, courseId) + `/schedule`;

export const APP_CLASS_GROUP_OVERVIEW = '/app/overview/classGroups/:classGroupId';
export const APP_CLASS_GROUP_OVERVIEW_IDS = (classGroupId, tutorialGroupId) => `/app/overview/classGroups/${classGroupId}`;

export const APP_CLASS_GROUP_TUTORIAL_GROUP_OVERVIEW = '/app/overview/classGroups/:classGroupId/tutorialGroups/:tutorialGroupId';
export const APP_CLASS_GROUP_TUTORIAL_GROUP_OVERVIEW_IDS = (classGroupId, tutorialGroupId) => `/app/overview/classGroups/${classGroupId}/tutorialGroups/${tutorialGroupId}`;

export const APP_END_COURSE = '/app/end-of-course/classGroups/:classGroupId/courses/:courseId';
export const APP_END_COURSE_IDS = (classGroupId, courseId) => `/app/end-of-course/classGroups/${classGroupId}/courses/${courseId}`;

export const HOME = '/app/courses';
export const APP_CONTACT = '/app/contact';
export const APP_CONTACT_DEVICE_CHECK = '/app/contact-device-check';

export let APP_MESSAGES = '/app/messages';

export const APP_DEVICE_CHECK = '/app/deviceCheck';
export const APP_BROWSER_CHECK = '/app/browserCheck';

export const APP_ACCOUNT = '/app/account';
export const SIGN_UP = APP_ACCOUNT + '/signup';
export const SIGN_IN = APP_ACCOUNT + '/signin';
export const SIGN_IN_LINK = APP_ACCOUNT + '/signin-with-link';
export const PASSWORD_FORGET = APP_ACCOUNT + '/pw-forget';
export const PASSWORD_RESET = APP_ACCOUNT + '/pw-reset';

export const APP_TUTOR = '/app/tutor';
export const APP_TUTOR_DASHBOARD = '/app/tutor/dashboard';
export const APP_TUTOR_FEEDBACK = APP_TUTOR + '/feedback';
export const APP_TUTOR_PROGRESS = APP_TUTOR + '/progress';
export const APP_TUTOR_COURSE_COMPLETION = APP_TUTOR + '/courseCompletion';
export const APP_TUTOR_PROGRESS_ACTIVITY = APP_TUTOR + '/dashboard/classGroups/:classGroupId/courses/:courseId/activity/:activityId/uid/:userId';
export const APP_TUTOR_PROGRESS_ACTIVITY_IDS = (classGroupId, courseId, activityId, userId) => `${APP_TUTOR}/dashboard/classGroups/${classGroupId}/courses/${courseId}/activity/${activityId}/uid/${userId}`;
export const APP_TUTOR_STATISTICS = APP_TUTOR + '/statistics';

export const APP_ADMIN = '/app/admin';
  export const APP_ADMIN_SELL = APP_ADMIN + '/sell';
  export const APP_ADMIN_CLASS_GROUP_CREATION = APP_ADMIN + '/classGroupCreation';
  export const APP_ADMIN_USERS = APP_ADMIN + '/users';
  export const APP_ADMIN_COURSE_DIAGRAM = APP_ADMIN + '/course/:courseStatus/:courseId/preview';
  export const APP_ADMIN_CONTENT_PREVIEW = APP_ADMIN + '/content/preview';
    export const APP_ADMIN_PREVIEW_ACTIVITY = APP_ADMIN_CONTENT_PREVIEW + '/activity/:entryId';
  export const APP_ADMIN_RECORDINGS = APP_ADMIN + '/recordings';
  export const APP_ADMIN_WEBINAR_INFO = APP_ADMIN + '/webinar-info';
  export const APP_ADMIN_TUTORIALS = APP_ADMIN + '/tutorials';
  export const APP_ADMIN_MESSAGE_VIEW = APP_ADMIN + '/messageAdminView';

  export const APP_TESTROOM = '/app/room/:region/qrjehfrw8eh8'
  export const APP_NEWTESTROOM = '/app/videocall/:region/:roomId'

