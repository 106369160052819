import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth?: number;
  }
}

const palette = {
  primary: {
    main: '#F45F00',
    light: '#F2DFD0',
    gray: '#D2D3D5',
    black: '#130D1F',
    white: '#fff',
    gradient: 'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
  },
  secondary: {
    main: '#75CCBD',
    light: '#CBE4DE',
    gradient: 'linear-gradient(152.51deg, #14FFD7 -64.25%, #75CCBD 105.81%)',
  },
  error: {
    main: '#D4483C',
    light: red.A100,
  },
  background: {
    default: '#fafafa',
    gray: '#f0f0f0',
    dark:  '#EEEEEE',
    white: '#FFF',
  },
  text: {
    dark: '#130D1F',
    black: '#000',
    gray: '#555',
    light: '#F2DFD0',
    primary: '#130D1F',
    secondary: '#130D1F',
    white: '#fff',
  },
}

const appTheme = createTheme({
  typography: {
    fontFamily: ['IBM Plex Sans'].join(','),
    h1: {
      fontSize: '2.98575rem',
    },
    h2: {
      fontSize: '2.4883125rem',
      lineHeight: '125%',
    },
    h3: {
      fontSize: '39px',
      lineHeight: '125%',
    },
    h4: {
      fontWeight: 'normal',
      fontSize: '33px',
      lineHeight: '125%',
    },
    h5: {
      fontSize: '1.4375rem',
    },
    h6: {
      fontSize: '1.1875rem',
    },
    body1: {
      fontSize: '1.1875rem',
    },
    body2: {
      fontSize: '1rem',
    },
    button: {
      fontSize: '1.1875rem',
      fontWeight: 'bolder',
      textTransform: 'none',
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '1.4rem',
    }

  },
  overrides: {
    // MuiPaper: {
    //   root: {
    //     boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    //   },
    //   rounded: {
    //     borderRadius: 5,
    //   }
    // },
    MuiFormLabel: {
      root: {
        paddingLeft: '10px',
        '&$focused': {
          color: palette.secondary.main,
        }
      },
    },
    MuiTextField: {
      root: {
        // backgroundColor: palette.secondary.light,
        color: palette.background.default,
        borderRadius: '2px 2px 0 0',
      },
    },
    MuiInputBase: {
      root: {
        border: 'none',
        padding: '9px 18px',
        margin: 4,
        color: palette.text.primary,
        fontFamily: 'IBM Plex Sans',
        fontWeight: 600,
        fontSize: '19px',
        lineHeight: '125%',
        "&$disabled": {
          backgroundColor: '#ddd',
        }
      },
      input: {
        borderRadius: 5,
        opacity: 0.4,
        // padding: '20px 16px',
        // border: '1px solid rgba(15, 5, 24, 0.6)',
        boxSizing: 'border-box',
        '&::placeholder': {
          color: '#888',
          opacity: 1,
        },
        "&$disabled": {
          color: '#777'
        }
      },
    },
    MuiInput: {
      input: {
        marginLeft: '10px',
        color: palette.text.primary,
        
      },
      underline: {
        '&:after': {
          border: 'none',
          // borderBottomColor: palette.secondary.main,
        }
      }
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
        boxShadow: 'none',
      },
      containedPrimary: {
        boxShadow: 'none',
        borderRadius: 10,
        background: 'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
        color: palette.primary.light,
        '&:hover': {
          boxShadow: 'none',
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
          color: palette.primary.light,
        },
      },
      containedSecondary: {
        boxShadow: 'none',
        borderRadius: 10,
        border: '1px solid',
        borderImage: 'linear-gradient(#FFAF75, #F45F00) -1',
        color: '#F45F00',
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: '#F45F00',
          color: '#fff',
        },
      },
    },
    // MuiError: {
    //   color: palette.background.default,
    //   padding: '5px',
    // },
    MuiFormHelperText: {
      root: {
        color: palette.background.default,
        padding: '5px',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 20,
        padding: 10,
      },
      root: {
        boxShadow: 'none',
        // backgroundColor: palette.primary.light,
      },
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: palette.secondary.main,
        }
      }
    }
  },
  palette,
  // @ts-ignore
  footerHeight: 72,
  mobileFooterHeight: 56,
  sidebarWidth: 300,
  sidebarMobileHeight: 90,
  sidebarMobilePadding: 8,
  participantBorderWidth: 2,
  mobileTopBarHeight: 52,
  rightDrawerWidth: 320,
  brand: ''
});

export default appTheme;
